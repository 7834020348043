import React from 'react';

import Layout from '@layouts';
import { Banners, SEO, Donate } from '@components';

const { Banner } = Banners;

const Index = ({ location }) => (
  <Layout
    bannerChildren={
      <Banner
        title='Donate'
        description='Your support is critical, help us deliver on our mission.'
      />
    }
    bannerStyle={{ paddingBottom: '150px' }}
  >
    <SEO
      title='DONATION PAGE'
      description='Your support is critical, help us deliver on our mission.'
    />
    <Donate location={location} />
  </Layout>
);

export default Index;
